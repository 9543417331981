body {
  .landing-page {
    width: 100%;
    height: 100vh;

    .logo {
      animation-name: slideInLeft;
      animation-duration: 2s;
      text-align: center;
      min-width: 100%;
      position: initial;
      img {
        width: 20%;
      }
    }
    .head {
      animation-name: slideInLeft;
      animation-duration: 2s;
      margin: 10px 50px;
      .head-title {
        margin: 0;
        h1 {
          font-size: 5.5rem;
          font-weight: 900;
        }
        h2 {
          font-size: 2.2rem;
          font-weight: 800;
        }
        h3 {
          font-weight: 10;
          width: 28%;
        }
      }
      h1,
      h2,
      h3 {
        color: $font;
        margin-block-start: -0.3em;
        margin-block-end: 0.01em;
        //text-shadow: 4px 4px 4px rgb(199, 199, 199);
      }
    }
    .floating {
      animation-name: slideInLeft;
      animation-duration: 2s;
      bottom: 1px;
      position: fixed;
      right: 2%;
      width: 4.5%;
      padding-bottom: 10px;
      .medium {
        border-radius: 40px;
        background-color: $font;
        padding: 12px 14px;
        img {
          width: 100%;
        }
      }
    }
    .section {
      position: fixed;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 30vw);
      grid-auto-rows: auto;
      grid-gap: 0.5rem;
      margin-top: 20px;
      bottom: 1px;
      .home,
      .bussines {
        animation-name: slideInLeft;
        animation-duration: 2s;
        justify-content: center;
        justify-items: center;
        background-color: $primary;
        height: 220px;
        h1 {
          font-weight: 800;
          margin: 0;
        }
        h2 {
          font-weight: 500;
          font-size: 5rem;
        }
        h1,
        h2,
        h3 {
          color: $font;
        }
        h3 {
          font-family: 750 !important;
        }
      }
      .home:hover,
      .bussines:hover {
        cursor: pointer;
        background-color: $bg-button;
        .section-button {
          button {
            font-style: oblique;
            background-color: $font;
            color: $primary;
            font-weight: bold;
            font-size: 0.9rem;
          }
        }
      }

      .center {
        text-align: center !important;
        .section-parragraph {
          height: 3rem;
          padding: 1px 2rem;
          .description {
            color: #ffffff;
            font-size: 0.8rem;
            text-align: center;
            justify-content: center !important;
          }
        }

        .section-icon {
          img {
            width: 15%;
          }
        }
        .section-icon-home {
          img {
            width: 15%;
            transform: scale(2); 
          }
        }
        .section-button {
          padding-top: 15px;
          button {
            background-color: $bg-button;
            color: $font;
            padding: 7px 45px;
            border: 1px solid $bg-button;
            border-radius: 45px;
            cursor: pointer;
            font-style: oblique;
          }
        }
      }
      .bussines {
        border-top-right-radius: 60px;
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  body {
    .landing-page {
      width: 100%;
      height: 100vh;

      .logo {
        animation-name: slideInLeft;
        animation-duration: 2s;
        text-align: center;
        min-width: 100%;
        position: initial;
        img {
          width: 20%;
        }
      }
      .head {
        animation-name: slideInLeft;
        animation-duration: 2s;
        margin: 10px 50px;
        .head-title {
          margin: 0;
          h1 {
            font-size: 5.5rem;
            font-weight: 900;
          }
          h2 {
            font-size: 2.2rem;
            font-weight: 800;
          }
          h3 {
            font-weight: 10;
            width: 28%;
          }
        }
        h1,
        h2,
        h3 {
          color: $font;
          margin-block-start: -0.3em;
          margin-block-end: 0.01em;
          //text-shadow: 4px 4px 4px rgb(199, 199, 199);
        }
      }
      .floating {
        animation-name: slideInLeft;
        animation-duration: 2s;
        bottom: 1px;
        position: fixed;
        right: 2%;
        width: 4.5%;
        padding-bottom: 10px;
        .medium {
          border-radius: 40px;
          background-color: $font;
          padding: 12px 14px;
          img {
            width: 100%;
          }
        }
      }
      .section {
        position: fixed;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 30vw);
        grid-auto-rows: auto;
        grid-gap: 0.5rem;
        margin-top: 20px;
        bottom: 1px;
        .home,
        .bussines {
          animation-name: slideInLeft;
          animation-duration: 2s;
          justify-content: center;
          justify-items: center;
          background-color: $primary;
          height: 180px;
          h1 {
            font-weight: 800;
            margin: 0;
          }
          h2 {
            font-weight: 500;
            font-size: 5rem;
          }
          h1,
          h2,
          h3 {
            color: $font;
          }
          h3 {
            font-family: 750 !important;
          }
        }
        .home:hover,
        .bussines:hover {
          cursor: pointer;
          background-color: $bg-button;
          .section-button {
            button {
              font-style: oblique;
              background-color: $font;
              color: $primary;
              font-weight: bold;
              font-size: 0.9rem;
            }
          }
        }

        .center {
          text-align: center !important;
          .section-parragraph {
            height: 3rem;
            padding: 1px 1rem;
            .description {
              color: #ffffff;
              font-size: 0.8rem;
              text-align: center;
              justify-content: center !important;
              margin: 0;
            }
          }

          .section-icon {
            img {
              width: 15%;
            }
          }
          .section-icon-home {
            img {
              width: 15%;
            transform: scale(2); 

            }
          }
          .section-button {
            padding-top: 0px;
            button {
              background-color: $bg-button;
              color: $font;
              padding: 7px 45px;
              border: 1px solid $bg-button;
              border-radius: 45px;
              cursor: pointer;
              font-style: oblique;
            }
          }
        }
        .bussines {
          border-top-right-radius: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  body {
    .landing-page {
      height: 60vh;
      .logo {
        padding-top: 20px;
        img {
          width: 80%;
        }
      }
      .head {
        margin: 10px 20px;
        //padding-top: 5px;
        .head-title {
          h1 {
            font-size: 3rem;
            font-weight: 900;
          }
          h2 {
            font-size: 1.2rem;
            font-weight: 800;
          }
          h3 {
            font-size: 1rem;
            font-weight: 1;
            width: 50%;
          }
        }
      }
      .floating {
        bottom: 0;
        width: 12%;
        padding-bottom: 0;
        .medium {
          border-radius: 40px;
          padding: 2px 5px;
          img {
            width: 100%;
          }
        }
      }
      .section {
        position: absolute;
        grid-template-columns: repeat(2, 49%);
        grid-template-rows: auto;
        height: 70%;
        margin: 0 1%;
        width: 98%;
        padding: 0;
        display: grid;
        align-items: end;
        & > div {
          border-radius: 25px;
        }
        .center {
          min-width: 0.6rem;
          .description {
            padding: 0 5px;
            font-size: 0.6rem;
            font-weight: normal;
          }
          .description.pg-home {
            padding: 5px 40px;
          }
          .section-icon {
            //padding-top: 5%;
            padding-bottom: 5%;
            img {
              width: 15%;
            }
          }
          .section-icon-home {
            padding-bottom: 5%;
            img {
              width: 15%;
            transform: scale(2); 

            }
          }
          .section-button {
            button {
              padding: 4px 20px;
            }
          }
        }
        .home,
        .bussines {
          height: 40%;
          width: 99%;
          h1 {
            font-size: 1.2rem;
            margin: 10px 10px;
          }
        }

        .bussines {
          border-top-right-radius: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  body {
    .landing-page {
      height: 60vh;
      max-width: 100vw;
      max-height: 100vh;
      .logo {
        padding-top: 20px;
        img {
          width: 80%;
        }
      }
      .head {
        margin: 40px 20px;

        //padding-top: 5px;
        .head-title {
          h1{
            font-size: 3rem;
            font-weight: 900;
            width: 0.3rem;
            margin-block-end: 0.17em;

          }
          h2 {
            font-size: 1.2rem;
            font-weight: 800;
            margin-block-end: 0.47em;
          }
          h3 {
            font-size: 1rem;
            font-weight: 1;
            width: 50%;
          }
        }
      }
      .floating {
        display: none;
        bottom: 0;
        right: 0.5%;
        padding-bottom: 0;
        .medium {
          border-radius: 40px;
          padding: 2px 5px;
          img {
            width: 100%;
          }
        }
      }
      .section {
        height: 14rem;
        .center {
          .description {
            max-width: unset;
            width: unset;

            font-size: 0.6rem;
            font-weight: normal;
          }
          .description.pg-home {
            padding: 0;
          }
          .section-icon {
            img {
              width: 15%;
            }
          }
          .section-icon-home {
            img {
              width: 15%;
            transform: scale(2); 

            }
          }
          .section-button {
            button {
              padding: 4px 20px;
              padding-top: 5px;
            }
          }
          .section-parragraph {
            padding: 0 0.2rem;
          }
        }
        .home,
        .bussines {
            height: 90%;
          h1 {
            font-size: 1.2rem;
            margin: 10px 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px)  {
    
    body {
        .landing-page {
          height: 60vh;
          max-width: 100vw;
          max-height: 100vh;
          .logo {
            padding-top: 20px;
            img {
              width: 80%;
            }
          }
          .head {
            margin: 40px 20px;
    
            //padding-top: 5px;
            .head-title {
              h1 {
                font-size: 3rem;
                font-weight: 900;
                width: 0.3rem;
              }
              h2 {
                font-size: 1.2rem;
                font-weight: 800;
              }
              h3 {
                font-size: 1rem;
                font-weight: 1;
                width: 50%;
              }
            }
          }
          .floating {
            display: none;
            bottom: 0;
            right: 0.5%;
            padding-bottom: 0;
            .medium {
              border-radius: 40px;
              padding: 2px 5px;
              img {
                width: 100%;
              }
            }
          }
          .section {
            height: 15rem;
            .center {
              .description {
                max-width: unset;
                width: unset;
    
                font-size: 0.6rem;
                font-weight: normal;
              }
              .description.pg-home {
                padding: 0;
              }
              .section-icon {
                //padding-top: 50px;
                img {
                  width: 15%;
                }
              }
              .section-icon-home {
                //padding-top: 50px;
                img {
                  width: 15%;
            transform: scale(2); 

                }
              }
              .section-button {
                button {
                  padding: 0px 10px;
                  padding-top: 3px;
                }
              }
              .section-parragraph {
                padding: 0 0.2rem;
              }
            }
            .home,
            .bussines {
                height: 75%;
                width: 99%;
              h1 {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
}
