.loader {
    background-color: $primary;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader_center {
    width: 120px;
  }